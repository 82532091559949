@use './assets/styles/ghost_screen';
@use './assets/styles/ghost_cards_min';
@use './assets/styles/ghost_code_injections';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './app/themes/angular-calendar';

body {
  background: white;
}

.clickable {
  cursor: pointer;
}

.button-menu {
  display: flex;
  justify-content: flex-end;

  &__button {
    padding: 5px;
    margin-left: 5px;
  }
}

.truncate {
  line-height: 1.3;
}

.bg-primary {
  background: var(--primary);
  background-color: var(--primary);
}

.text-primary {
  color: var(--text-primary);
}

.bg-accent {
  background: var(--accent);
  background-color: var(--accent);
}

.text-accent {
  color: var(--text-accent);
}

.text-header-bar {
  color: var(--text-header-bar);
}

button {
  &.accent {

    &:hover {
      background: var(--primary);
    }
  }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.modal-header {
  padding-top: env(safe-area-inset-top, 1rem);
  padding-right: env(safe-area-inset-right, 1rem);
  padding-left: env(safe-area-inset-left, 1rem);
}

.modal-fullscreen {
  --width: 100dvw !important;
  --height: 100dvh !important;
  --max-width: 100dvw;
  --max-height: 100dvh;
}

.primary {
  background-color: var(--primary);
}

.modal-calculator {
  .modal-wrapper {
    max-width: 278px;
    max-height: 554px;
    border-radius: 6px;
    margin: 4px;
  }
}

.selected-tile {
  box-shadow: 0 0 0 1.5pt var(--accent);
}

// on IOS there is no placeholder for the date and the input fields shrink and are then not clickable
// https://codepen.io/helev/pen/VwZbgem
input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

input::-webkit-date-and-time-value {
  text-align: left;
  width: 100%;
}

.text-accent {
  color: var(--text-accent);
}

input[type="search"] {
  background: white;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}


ion-button {
  color: var(--text-primary);
}

div.emoji,
span.emoji {
  display: inline-block;
  color: inherit;
}

.emoji::before {
  content: attr(data-emoji);
}

.safe-padding-up {
  padding-right: env(safe-area-inset-right, 2rem);
  padding-top: env(safe-area-inset-top, 2rem);
  padding-left: env(safe-area-inset-left, 2rem);
}

.safe-padding-down {
  padding-right: env(safe-area-inset-right, 2rem);
  padding-bottom: env(safe-area-inset-bottom, 2rem);
  padding-left: env(safe-area-inset-left, 2rem);
}

.modal-wrapper .sc-ion-modal-ios {
  transform: translate3d(0, 0%, 0) !important;
}

.info-post {
  a {
    @apply underline
  }
}

.ion-page {
  overflow: visible !important;
}

div {
  user-select: text;
}

.ghost-content {
  font-size: 62.5%;
  --ghost-accent-color: #0b3a2b;
  @include ghost_screen.ghost-screen;
  @include ghost_cards_min.ghost-card;
  @include ghost_code_injections.code-injections;
}

mention {
  @apply bg-gray-700 text-gray-100 rounded;
   padding: 2px 6px;
}


@media print {
  .h-full {
    height: auto !important;
  }
  * {
    overflow: visible !important;
  }

  body {
    height: auto;
    width: auto;
    max-height: unset;
    max-width: unset;
    position: relative;
    overflow: visible;
  }

  .print-hidden {
    display: none !important;
  }

  .print-height-auto {
    height: auto !important;
  }

  .print-position {
    position: unset;

  }

  app-info-details, app-my-business-info-details, app-tabs  {
    height: auto !important;
    overflow: visible !important;
  }

}
